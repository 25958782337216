import i18n from 'i18next';
import { initReactI18next, } from 'react-i18next';
import en from './locales/en.json'
import cn from './locales/cn.json'
import jp from './locales/jp.json'

//从ISO639标准转化到本网页的
function ISO639ToMy(jsLang) {
  if (jsLang.indexOf('zh') >= 0) {
    return 'cn'
  }
  else if (jsLang.indexOf('ja') >= 0) {
    return 'jp'
  }
  return 'en'
}
const lslang = localStorage.getItem('lang')
//如果没有默认的语言则选择浏览器中的语言
let lng = lslang || ISO639ToMy((navigator.language || navigator.browserLanguage).toLowerCase()) || 'cn'
//保存浏览器中的语言
if (!lslang) {
  localStorage.setItem('lang', lng)
}
// console.log(lng);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      cn: {
        translation: cn
      },
      en: {
        translation: en
      },
      jp: {
        translation: jp
      },
    },
    lng: lng,
    fallbackLng: lng,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n
