import React,{Component} from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import {asyncComponent} from './asyncComponent'




const Home    =asyncComponent(()=> import ('../pages/home'))
const Product =asyncComponent(()=> import ('../pages/product'))
const About = asyncComponent(() => import('../pages/about'))
const News = asyncComponent(() => import('../pages/news'))
const Academic = asyncComponent(() => import('../pages/academic'))
const Join = asyncComponent(() => import('../pages/join'))
const Contact = asyncComponent(() => import('../pages/contact'))
const NewsDetail = asyncComponent(() => import('../pages/details'))
const AcaDetail = asyncComponent(() => import('../pages/aca-details'))
const proDetail =asyncComponent(() => import('../pages/product-details'))

const scan2D =asyncComponent(() => import('../pages/scan2D'))

class Router extends  Component{
  render(){
    return(
      <BrowserRouter>
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/product" component={Product} />
          <Route path="/about" component={About} />
          <Route path="/news" component={News} />
          <Route path="/details/:id" component={NewsDetail} />
          <Route path="/academic" component={Academic} />
          <Route path="/acadetails/:id" component={AcaDetail} />
          <Route path="/prodetails" component={proDetail} />
          <Route path="/join" component={Join} />
          <Route path="/contact" component={Contact} />
          <Route path="/scan2D" component={scan2D} />
          <Route path="/" component={Home} exact />
          <Redirect path="/" to='/home'/>
        </Switch>
      </BrowserRouter>
    )
  }
}
export default Router