; (function (psdw) {
  var dpr = 0,
    rem = 0,
    scale = 0
  var docDOM = document.documentElement
  dpr = window.devicePixelRatio
  var currentWidth = docDOM.clientWidth
  scale = currentWidth / psdw
  rem = psdw /10
  rem = rem * scale
  // console.log(rem)
  docDOM.style.fontSize = rem + 'px'
  docDOM.setAttribute('data-dpr', dpr)
})(750)
