import React,{Component} from 'react';
import './App.css';
import Router from './router'
import { Provider } from 'react-redux'
import store from './store'
import FastClick from 'fastclick'

if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function () {
    FastClick.attach(document.body);
  }, false);
}

class App extends Component{
  render(){
    return(
      <Provider store={store}>
        <Router/>
      </Provider>
    )
  }
}
export default App;
