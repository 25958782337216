import { combineReducers } from 'redux';
import * as actionTypes from './action-type'


const initState = {
  news: '',
  page: '',
  top: 0,
  more: true,
};
// const initTab={
//   tab:'',
// };

const curTab = (state = { tab: '' }, action) => {
  switch (action.type) {
    case actionTypes.SAVE_TAB:
      return { tab: action.tab }
    default:
      return state;
  }
};
const curNews = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_NEWS:
      return { ...state, news: action.news }
    case actionTypes.SAVE_PAGE:
      return { ...state, page: action.page }
    case actionTypes.SAVE_TOP:
      return { ...state, top: action.top }
    case actionTypes.SAVE_MORE:
      return { ...state, more: action.more }
    default:
      return state;
  }
};
export default combineReducers({
  curTab, curNews
})
