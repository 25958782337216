const isTest = window.location.host == "localhost:3000"
const mobileWebHref = isTest ? "http://localhost:3000" : "https://m.morphogo.com"
const pcWebHref = isTest ? "http://0.0.0.0:8083" : "https://morphogo.com"
const key = "platform"
const mobile = "mobile"
const pc = "pc"
const self = mobile
const another = pc
const anotherWeb = pcWebHref
function isMobile() {
  const regExp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regExp.test(navigator.userAgent);
}
function platformChoose() {
  let isForce = false
  let href = window.location.href
  let lastSplitChar = href.lastIndexOf("?")
  let search = ""
  let bef = href
  if (lastSplitChar > -1) {
    search = href.substring(lastSplitChar)
    bef = href.substring(0, lastSplitChar)
  }
  let params = search.substring(1).split("&")
  let i = 0
  for (i = 0; i < params.length; i++) {
    let param = params[i]
    if (param.split("=")[0] == "force") {
      isForce = true
      break
    }
  }
  if (isForce) {
    //强制使用自身
    localStorage.setItem(key, self)
    if (params.length > i) {
      params.splice(i, 1)
      if (params.length > 0) {
        window.location.href = bef + "?" + params.join("&")
        return true
      } else {
        window.location.href = bef
        return true
      }
    }
  } else {
    let platform = localStorage.getItem(key)
    //如果选择了手机端则跳转到手机
    if (platform == another) {
      window.location.href = anotherWeb + "?force"
      return true
    } else if (!platform) {
      //没有选择则根据浏览器类型进行跳转
      if (!isMobile()) {
        window.location.href = pcWebHref + "?force"
        return true
      }
    }
  }
}
export function setAndJumpPlatform() {
  localStorage.setItem(key, another)
  window.location.href = anotherWeb + "?force"
}
export default platformChoose